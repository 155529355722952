// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-babybauch-js": () => import("./../../../src/pages/babybauch.js" /* webpackChunkName: "component---src-pages-babybauch-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-familie-js": () => import("./../../../src/pages/familie.js" /* webpackChunkName: "component---src-pages-familie-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hochzeiten-js": () => import("./../../../src/pages/hochzeiten.js" /* webpackChunkName: "component---src-pages-hochzeiten-js" */),
  "component---src-pages-hunde-js": () => import("./../../../src/pages/hunde.js" /* webpackChunkName: "component---src-pages-hunde-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../../../src/pages/ueberMich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */),
  "component---src-pages-widerrufbelehrung-js": () => import("./../../../src/pages/widerrufbelehrung.js" /* webpackChunkName: "component---src-pages-widerrufbelehrung-js" */)
}

